import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import {
  Title,
  Button,
  Section,
  Box,
  Text,
  InputGroup,
  Span,
} from "../../components/Core";

import { device } from "../../utils";

import imgL2HeroOval1 from "../../assets/image/jpeg/d1.jpg";
import imgL2HeroOval2 from "../../assets/image/png/l2-hero-oval2.png";
import imgL2HeroBg from "../../assets/image/jpeg/dermamina-clinic-treatments.jpg";
import imgL3HeroBg from "../../assets/image/jpeg/non-surgical-treatments.jpg";

import nonsurgical from "../../assets/image/jpeg/nonnose.jpg";
import trust from "../../assets/image/png/trust.svg"

const SectionStyled = styled(Section)`

  @media ${device.lg} {
  display:none;
  }
`;


const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-bottom:80px;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;
  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }
  &.main {
    background: #154147;
    color: #efe8de;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: -5px -6px 10px #efe8de;
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    box-shadow: 5px 10px 15px #efe8de;
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #efe8de;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #222;
      }
    }
  }
`

const ImageTop = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  position: absolute;
  top: -11%;
  left: -25%;
  border-radius: 50%;
  overflow: hidden;
  @media ${device.lg} {
    left: 10%;
  }
`;

const ImageBottom = styled(Box)`
  position: absolute;
  right: -13%;
  top: 54%;
  @media ${device.lg} {
    right: -23%;
  }
  @media ${device.xl} {
    right: -28%;
  }
`;

const Nonmobilehero = () => {
  return (
    <>
      {/* <!-- Hero Area --> */}
      <SectionStyled  py={0}>
     

            <div className=" pt-5  mb-0">
              <img src={nonsurgical} alt="Non Surgical Rhinoplasty London" width="100%" />
              </div>
                
        
              
         
      </SectionStyled>
    </>
  );
};

export default Nonmobilehero;
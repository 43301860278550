import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";
import Link from 'gatsby-link'
import { FaArrowRight } from "react-icons/fa";
import { Title, Button, Section, Box, Text ,Span } from "../../components/Core";
import { device } from "../../utils";
import ShowMoreText from 'react-show-more-text';
import imgwork from "../../assets/image/jpeg/how-prp-works.png";
import imgHalf from "../../assets/image/jpeg/dermamina-clinic.jpg";

import Imaging from "../../assets/image/jpeg/about-derma.jpg";

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;
text-transform:uppercase;
  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;


const ImageHalfContainer = styled(Box)`
  position: static;
  right: -260px;

  height: 100%;

  .img-half {
    height: auto;
    @media ${device.lg} {
      height: 100%;
    }
  }
  @media ${device.lg} {
    position: absolute !important;
    height: 100%;
    right: -500px;
  }
  @media ${device.xl} {
    position: absolute !important;
    height: 100%;
    right: -390px;
  }
`;

const ImageBottom = styled(Box)`
  border: 10px solid transparent;
  box-shadow: ;
  position: absolute;
  left: 15px;
  bottom: 10%;
  border-radius: 50%;
  overflow: hidden;
  max-width: 50%;

  @media ${device.sm} {
    bottom: 15%;
    left: 0;
  }
  @media ${device.md} {
    max-width: 50%;
  }
  @media ${device.lg} {
    top: 55%;
    left: -95px;
    bottom: auto;
    max-width: 100%;
  }
`;

const Buttonnew = styled.button `
color: #222;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #efe8de;
  border: none;
  padding: 1rem 2.5rem;
  font-weight: 600;
  font-family: termina, sans-serif;
  text-transform: uppercase;
  font-size: 0.7rem;
  position: relative;
  border-radius: 100px;
  z-index: 1;
  margin-bottom:80px;
  margin-top:20px;
  margin-right: 1rem;
  cursor: pointer;
  outline: none;
  transform-style: preserve-3d;
  transition: 0.2s ease-out;
  &:hover {
    &.main span {
      color: #fff;
      mix-blend-mode: difference;
    }
    &.alt span {
      mix-blend-mode: difference;
      color: white;
    }
  }
  &.main {
    background: #00afc9;
    color: #efe8de;
  }
  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
    
    filter: brightness(1.2);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 100px;
    z-index: 2;
  
    filter: brightness(0.8);
    transition: 0.3s ease-out;
    pointer-events: none;
  }
  &:active {
    &.alt {
      &::after {
        box-shadow: inset 4px 4px 8px #efe8de;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #efe8de;
      }
    }
    &.main {
      &::after {
        box-shadow: 2px 5px 6px #00afc9;
      }
      &::before {
        box-shadow: inset -2px -2px 8px #00afc9;
      }
    }
  }
`

const ComboBE = () => (
  <>
    <Section py={4} borderTop="1px solid #C2D9E1" borderBottom="1px solid #C2D9E1"  bg="#FFD9CF">

    
      <Container  >
        <Row className="align-items-center">
 
     

          <Col className="order-lg-2"
            md={6}
            lg="12"
            data-aos="fade-up"
            data-aos-duration="500"
            data-aos-once="false"
          >
           
                <HeroT color="dark">
                PRP Hair & <Span color="#00afc9"> Mesotherapy </Span>
                </HeroT>
                <Text color="dark">
                Recent developments in restoration and growth medicine have shown that combining Platelet Rich Plasma (PRP) and Mesotherapy has been successful in the hair strengthening and growth in men and women of all ages. 


 <br /> <br />
 PRP  for hair is a minimally invasive, non-surgical procedure that promotes healthy hair growth using your body’s own natural growth elements, making your thinning hair grow back thicker, fuller and stronger. Mesotherapy for hair is a non-invasive and non-surgical technique done by injections of specialised cocktails into the mesoderm or middle layer of the skin/scalp. The elements contained in the superficial microinjections contain ample nutrients and growth factors, needed for the strengthening and survival of hair follicles. 
By combining mesotherapy and PRP, you can gain the benefits of both, allowing Dermamina London to fully utilise the healing capabilities of the PRP and the unique fusion of cocktail of mesotherapy that is needed to stimulate hair follicles and promote a healthy scalp. 


                </Text>

              
            
          </Col>
        
        </Row>

      
      </Container>
      
   
    </Section>
  </>
);

export default ComboBE;

import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import trust from "../../assets/image/png/trust.svg"
import { Title, Section, Box, Text, Span } from "../../components/Core";
import { device } from "../../utils";

import imgMobile from "../../assets/image/png/mobile.png";
import icon3dModel from "../../assets/image/png/icon-3d-model.png";
import iconLayout from "../../assets/image/png/icon-layot-25.png";
import Videos from "../../sections/non/video.js";

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: black;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const ContentCard = ({ iconImage, title, className, children, ...rest }) => (
  <Box
    {...rest}
    className={`d-flex align-items-start justify-content-start ${className}`}
  >
    <Box mr={4}>
      <img src={iconImage} alt="" />
    </Box>
    <Box>
      <Title variant="card" fontSize="24px" mb={3}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </Box>
  </Box>
);

const ImgStyled = styled.img`
  transform: rotate(10deg);
  box-shadow: ${({ theme }) => `0 52px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  max-width: 70%;
  @media ${device.sm} {
    max-width: 50%;
  }
  @media ${device.md} {
    max-width: 33%;
  }
  @media ${device.lg} {
    max-width: 100%;
  }
  @media ${device.xl} {
    margin-left: 50px;
  }
`;

const MesoThree = () => (
  <>
    {/* <!-- Content section 2 --> */}
    <Section bg="rgba(217, 211, 210, .5)" py="4" className="pb-md-5 mb pt-md-5"  >
      <Container className="pb-md-5 mb pt-md-5">

      <Row className="align-items-center">
        
        <Col lg="6" md="9">
          <Box className="pt-0 pt-lg-0">
          <HeroT>MESOTHERAPY <Span color="primary"> FOR FACIAL REJUVENTATION</Span></HeroT>
          
            <Text>
            Our Mesotherapy Skin Treatment can be tailored to address a variety of skin concerns while nourishing, brightening and rejuvenating the skin, and stimulating the production of the skins own collagen and elastin, which improves the skins elasticity, adding a youthful and radiant look. 

            <br />
            Mesotherapy not only improves dull, tired-looking skin and superficial wrinkles, but it also aids the skin in flushing out ageing toxins by improving sluggish blood circulation. This treatment can also be used to address hyperpigmentation, acne, and give the skin a glow from within. Skin will become more radiant, hydrated, nourished and firmer with an improved texture. <br />  <br />

            </Text>
        
      

          </Box>
        </Col>



                
        <Col lg="6" md="9">
          <Box className="pt-0 pt-lg-0">
          <HeroT>MESOTHERAPY FACIAL REJUVENTATION <Span color="primary"> RESULTS </Span></HeroT>
          
            <Text>
            Fine lines and wrinkles are encouraged to fade away for a more youthful, even skin tone, while dull or fatigued facial skin is rejuvenated for a more radiant, healthy appearance. You might expect a tighter, more contoured appearance in the treated area. Some effects can be seen after just one treatment, but a course of up to three treatments at regular intervals is recommended, with maintenance sessions once every six to twelve months if needed.

            </Text>
     

          </Box>
        </Col>
       
      </Row>
       

      
      </Container>
    </Section>
  </>
);

export default MesoThree;
